import "./about.scss";

export default function About() {
  const skills = [
    {
      href: "https://react.dev/",
      src: "https://res.cloudinary.com/dhzmockpa/image/upload/v1738496735/Personal%20Portfolio/v5/icons/react.svg",
      alt: "React",
    },
    {
      href: "https://expressjs.com/",
      src: "https://res.cloudinary.com/dhzmockpa/image/upload/v1738496735/Personal%20Portfolio/v5/icons/expressjs.svg",
      alt: "Express",
    },
    {
      href: "https://nodejs.org/",
      src: "https://res.cloudinary.com/dhzmockpa/image/upload/v1738496735/Personal%20Portfolio/v5/icons/nodejs.svg",
      alt: "NodeJs",
    },
    {
      href: "https://www.python.org/",
      src: "https://res.cloudinary.com/dhzmockpa/image/upload/v1738496735/Personal%20Portfolio/v5/icons/python.svg",
      alt: "Python",
    },
    {
      href: "https://angular.dev/",
      src: "https://res.cloudinary.com/dhzmockpa/image/upload/v1738496735/Personal%20Portfolio/v5/icons/angular.svg",
      alt: "Angular",
    },
    {
      href: "https://reactnative.dev/",
      src: "https://res.cloudinary.com/dhzmockpa/image/upload/v1738496735/Personal%20Portfolio/v5/icons/reactnative.svg",
      alt: "React Native",
    },
    {
      href: "https://expo.dev/",
      src: "https://res.cloudinary.com/dhzmockpa/image/upload/v1738496735/Personal%20Portfolio/v5/icons/expo.svg",
      alt: "Expo",
    },
    {
      href: "https://flask.palletsprojects.com/",
      src: "https://res.cloudinary.com/dhzmockpa/image/upload/v1738496735/Personal%20Portfolio/v5/icons/flask.svg",
      alt: "Flask",
    },
    {
      href: "https://www.langchain.com/",
      src: "https://res.cloudinary.com/dhzmockpa/image/upload/v1738496735/Personal%20Portfolio/v5/icons/langchain.svg",
      alt: "Langchain",
    },
    {
      href: "https://www.mongodb.com/",
      src: "https://res.cloudinary.com/dhzmockpa/image/upload/v1738496735/Personal%20Portfolio/v5/icons/mongodb.svg",
      alt: "MongoDB",
    },
    {
      href: "https://www.postgresql.org/",
      src: "https://res.cloudinary.com/dhzmockpa/image/upload/v1738496735/Personal%20Portfolio/v5/icons/postgresql.svg",
      alt: "PostgreSQL",
    },
    {
      href: "https://git-scm.com/",
      src: "https://res.cloudinary.com/dhzmockpa/image/upload/v1738496735/Personal%20Portfolio/v5/icons/git.svg",
      alt: "Git",
    },
    {
      href: "https://unity.com/",
      src: "https://res.cloudinary.com/dhzmockpa/image/upload/v1738496735/Personal%20Portfolio/v5/icons/unity.svg",
      alt: "Unity",
    },
  ];

  return (
    <div className="main-container">
      <div className="container-info">
        <div className="header">
          <div className="primary-color-header">About</div>
          <div className="secondary-color-header">Me</div>
        </div>
        <hr />
        <div className="content">
          <p>
            I'm a passionate Developer currently working at TCS, with a strong
            background in web development and hands-on experience in app
            development. I hold a B.Tech in Computer Science and Engineering
            from Vellore Institute of Technology, Chennai.
          </p>
          <p>
            Currently, I’m working on projects that combine web development with
            LLM tools like LangChain, LangGraph, and GenAI. My drive to
            constantly learn and adapt helps me keep up with the fast-evolving
            tech landscape, and I’m always eager to take on new challenges that
            help me grow and improve my skills.
          </p>
        </div>
        <div className="header">
          <div className="primary-color-header">Skills</div>
        </div>
        <hr />
        <div className="skill-carousel">
          {skills.map((skill) => (
            <a
              key={skill.alt}
              className="skill"
              href={skill.href}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={skill.src} alt={skill.alt} className="skill-icon" />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}
