import "./blog.scss";

export default function Blog() {
  const blogsData = [
    {
      img: "https://res.cloudinary.com/dhzmockpa/image/upload/v1738496721/Personal%20Portfolio/v5/projects/two-wheeler-traffic.jpg",
      title: "Build a Two-Wheeler Traffic Rule Violation Detector — No ML/DL!",
      brief:
        "Want to build a system that detects two-wheeler traffic rule violations? You’re in the right place! In this tutorial, I’ll show you how to create a simple violation detection system using Python and Roboflow. We’ll focus on three main violations:",
      url: "https://medium.com/@pratham52/build-a-two-wheeler-traffic-rule-violation-detector-no-ml-dl-0da3ad626c7d",
    },
    {
      img: "https://res.cloudinary.com/dhzmockpa/image/upload/v1738496710/Personal%20Portfolio/v5/blogs/local-ai-chatbot.jpg",
      title: "Create Your Own Local AI Chatbot with Ollama and LangChain",
      brief:
        "Have you ever used ChatGPT, Gemini, Claude, or any other generative AI application and wondered how you could build something similar on your own? If you’ve ever wanted to create a chatbot that can answer questions based on specific documents, data, or context, you’re in the right place. In this guide, we’ll walk through the process of developing a local AI chatbot using Ollama and LangChain. Whether you’re interested in personal projects or professional applications, this tutorial will equip you with the knowledge to get started.",
      url: "https://medium.com/@pratham52/create-your-own-local-ai-chatbot-with-ollama-and-langchain-ccd0a8c423e3",
    },
    {
      img: "https://res.cloudinary.com/dhzmockpa/image/upload/v1738496709/Personal%20Portfolio/v5/blogs/gravity-glide.png",
      title: "Building a simple cross platform 2D platformer using Unity",
      brief:
        "A user can play Gravity Glide, a game about switching gravity to evade obstacles, on their pc or smartphone. Create a script PlayerMovement.cs. Grab the player rigidbody and give it a constant velocity in x-axis of 5, and make it move in x-axis automatically. For gravity switching, setup touch input for android and spacebar for windows. Keep changing rigidbody gravity scale between 1 and -1 with every switch performed.",
      url: "https://devpost.com/software/gravity-glide-2d-platformer-one-button-cross-platform",
    },
    {
      img: "https://res.cloudinary.com/dhzmockpa/image/upload/v1738496710/Personal%20Portfolio/v5/blogs/morse-code-gofr.jpg",
      title: "Building a Simple Morse Code Translation API with GoFr",
      brief:
        "In this tutorial, we will be building a simple Morse Code Translation API using GoFr, a framework designed to simplify microservice development. GoFr is ideal for building lightweight APIs that are easy to deploy, manage, and monitor. If you’re new to GoFr or Go in general, this is a great way to get started.",
      url: "https://medium.com/@pratham52/building-a-simple-morse-code-translation-api-with-gofr-36d4f03abe50",
    },
    {
      img: "https://res.cloudinary.com/dhzmockpa/image/upload/v1738496710/Personal%20Portfolio/v5/blogs/free-api.jpg",
      title: "8 Interesting Free Must-Try APIs for Your Next Project",
      brief:
        "APIs are the secret sauce that adds exquisite flavour to a developer’s projects, acting as bridges that connect different software components and services. They give developers access to a wide range of functionalities and data, allowing them to improve their projects with features that they wouldn’t have had the resources to build from scratch. Whether it’s accessing real-time weather data, retrieving information about movies and TV shows, or integrating authentication SDK into applications, APIs empower developers to create richer, more dynamic experiences for their users. In this article, we’ll explore eight fun and free(mium) APIs that are sure to inspire your next project.",
      url: "https://medium.com/@pratham52/8-interesting-free-must-try-apis-for-your-next-project-d790938917ec",
    },
  ];

  return (
    <div className="main-container">
      <div className="container-info">
        <div className="header">
          <div className="primary-color-header">Blog</div>
        </div>
        <hr />
        <div className="blogs">
          {blogsData.map((blog, index) => (
            <a
              className="blog-card"
              key={index}
              href={`${blog.url}?ref=${window.location.host}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="blog-image">
                <img src={blog.img} alt="blog-img" />
              </div>
              <div className="content">
                <div className="blog-title">{blog.title}</div>
                <div className="blog-brief">{blog.brief}</div>
              </div>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}
