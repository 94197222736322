import "./portfolio.scss";
import { useState } from "react";

export default function Portfolio() {
  const [activeProjectType, setActiveProjectType] = useState("All");

  const projectTypes = ["All", "Web", "App", "DApp", "AI/ML", "Game"];
  const projects = [
    {
      name: "TL;DR AI",
      type: "Web",
      image:
        "https://res.cloudinary.com/dhzmockpa/image/upload/v1739558905/Personal%20Portfolio/v5/projects/tldr-ai.png",
      url: "https://tldr-ai.prathamjaiswal.com/",
    },
    {
      name: "Anifuri",
      type: "App",
      image:
        "https://res.cloudinary.com/dhzmockpa/image/upload/v1738496721/Personal%20Portfolio/v5/projects/anifuri.jpg",
      url: "https://github.com/pratham-jaiswal/anifuri/releases/latest",
    },
    {
      name: "Xpenzie",
      type: "App",
      image:
        "https://res.cloudinary.com/dhzmockpa/image/upload/v1738496721/Personal%20Portfolio/v5/projects/xpenzie.jpg",
      url: "https://github.com/pratham-jaiswal/xpenzie-expense-tracker/releases/latest",
    },
    {
      name: "Two Wheeler Traffic Rule Violation Detection",
      type: "AI/ML",
      image:
        "https://res.cloudinary.com/dhzmockpa/image/upload/v1738496721/Personal%20Portfolio/v5/projects/two-wheeler-traffic.jpg",
      url: "https://github.com/pratham-jaiswal/two-wheeler-traffic-rule-violation",
    },
    {
      name: "Legal AI",
      type: "AI/ML",
      image:
        "https://res.cloudinary.com/dhzmockpa/image/upload/v1738496721/Personal%20Portfolio/v5/projects/legal-ai.png",
      url: "https://legal-ai-chatbot.streamlit.app",
    },
    {
      name: "GenSea",
      type: "DApp",
      image:
        "https://res.cloudinary.com/dhzmockpa/image/upload/v1738496721/Personal%20Portfolio/v5/projects/gensea.png",
      url: "https://github.com/pratham-jaiswal/blockchain-social-media",
    },
    {
      name: "PokéTrivia",
      type: "Web",
      image:
        "https://res.cloudinary.com/dhzmockpa/image/upload/v1738496721/Personal%20Portfolio/v5/projects/poketrivia.png",
      url: "https://poketrivia.prathamjaiswal.com",
    },
    {
      name: "SharePad",
      type: "Web",
      image:
        "https://res.cloudinary.com/dhzmockpa/image/upload/v1738496721/Personal%20Portfolio/v5/projects/sharepad.png",
      url: "https://sharepad.prathamjaiswal.com",
    },
    {
      name: "Intrusion Detection in Imbalanced Network Traffic",
      type: "AI/ML",
      image:
        "https://res.cloudinary.com/dhzmockpa/image/upload/v1738496721/Personal%20Portfolio/v5/projects/intrusion-detection-network.jpg",
      url: "https://github.com/pratham-jaiswal/intrusion-detection-ml-dl",
    },
    {
      name: "Radiant Rush",
      type: "Game",
      image:
        "https://res.cloudinary.com/dhzmockpa/image/upload/v1738496721/Personal%20Portfolio/v5/projects/radiant-rush.png",
      url: "https://maxx-devs.itch.io/radiant-rush",
    },
    {
      name: "Ascend",
      type: "Game",
      image:
        "https://res.cloudinary.com/dhzmockpa/image/upload/v1738496721/Personal%20Portfolio/v5/projects/ascend.jpg",
      url: "https://maxx-devs.itch.io/ascend",
    },
    {
      name: "Fake News Detection",
      type: "AI/ML",
      image:
        "https://res.cloudinary.com/dhzmockpa/image/upload/v1738496721/Personal%20Portfolio/v5/projects/fake-news.jpg",
      url: "https://github.com/pratham-jaiswal/fake-news-detection-ml",
    },
  ];

  const filteredProjects = projects.filter(
    (project) =>
      activeProjectType === "All" || project.type === activeProjectType
  );

  return (
    <div className="main-container">
      <div className="container-info">
        <div className="header">
          <div className="primary-color-header">Portfolio</div>
        </div>
        <hr />
        <div className="project-type">
          {projectTypes.map((type) => (
            <div
              key={type}
              className={`project-type-item ${
                activeProjectType === type ? "active" : ""
              }`}
              onClick={() => setActiveProjectType(type)}
            >
              {type}
            </div>
          ))}
        </div>
        <div className="projects">
          {filteredProjects.map((project, index) => (
            <a
              key={index}
              className="project-card"
              href={project.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="image-container">
                <img src={project.image} alt={project.name} />
                <div className="overlay">
                  <i className="fa-solid fa-link"></i>
                </div>
              </div>
              <div className="project-name">{project.name}</div>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}
