import { useRef, useState } from "react";
import "./contact.scss";
import emailjs from "@emailjs/browser";

export default function Contact() {
  const [status, setStatus] = useState({
    message: "",
    type: "",
  });
  const [isSending, setIsSending] = useState(false);

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    setIsSending(true);

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAIL_SERVICE_KEY,
        process.env.REACT_APP_EMAIL_TEMPLATE_ID,
        form.current,
        {
          publicKey: process.env.REACT_APP_EMAIL_PUBLIC_KEY,
        }
      )
      .then(
        () => {
          setIsSending(false);
          form.current.reset();
          setStatus({
            message:
              "Your message has been successfully sent. I will get back to you as soon as possible.",
            type: "success",
          });
          setTimeout(() => {
            setStatus({
              message: "",
              type: "",
            });
          }, 5000);
        },
        (error) => {
          setIsSending(false);
          setStatus({
            message:
              "Oops! Something went wrong while sending your message. Please try again later or use alternative contact options below.",
            type: "error",
          });
          setTimeout(() => {
            setStatus({
              message: "",
              type: "",
            });
          }, 5000);
        }
      );
  };
  return (
    <div className="main-container">
      <div className="container-info">
        <div className="header">
          <div className="primary-color-header">Contact</div>
        </div>
        <hr />
        <form className="contact-form" ref={form} onSubmit={sendEmail}>
          <div className={`status ${status.type}`}>{status.message}</div>
          <input type="text" name="user_name" placeholder="Name" required />
          <input type="email" name="user_email" placeholder="Email" required />
          <textarea
            placeholder="Message"
            cols="30"
            rows="10"
            name="message"
            required
          ></textarea>
          <button type="submit" disabled={isSending}>{isSending ? "Sending..." : "Send"}</button>
        </form>
      </div>
    </div>
  );
}
