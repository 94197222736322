import "./navbar.scss";

export default function Navbar({ activePage, setActivePage }) {
    const pages = ["About", "Portfolio", "Resume", "Blog", "Contact"];

    const handlePageChange = (page) => {
        setActivePage(page);
    }
    return (
    <div className="navbar-container">
      <nav className="navbar">
        {pages.map((page) => (
          <button
            key={page}
            className={`nav-btn ${activePage === page ? "active" : ""}`}
            onClick={() => handlePageChange(page)}
          >
            {page}
          </button>
        ))}
      </nav>
    </div>
  );
}