import "./resume.scss";
const educationData = [
  {
    institute: "Vellore Institute of Technology, Chennai",
    duration: "2020 - 2024",
    description: "Bachelor of Technology in Computer Science and Engineering",
  },
  {
    institute: "The Assembly of God Church School",
    duration: "2006 - 2020",
    description:
      "Completed entire schooling with XI and XII as a Science major.",
  },
];

const experienceData = [
  {
    organisation: "Brainovision Solutions India Pvt. Ltd.",
    role: "WordPress Developer",
    duration: "2021",
    details: [
      "1-month summer internship (remote)",
      "Built an eCommerce platform using WordPress",
    ],
  },
  {
    organisation: "Tata Consultancy Services",
    role: "Developer",
    duration: "2024 - Present",
    details: [
      "Working on an AI orchestration platform.",
      "Developed backend endpoints using Flask.",
      "Developed frontend using Angular.",
      "Integrated Langchain and Langgraph to utilize LLMs for automation of tasks.",
      "Collaborated with other developers on a fullstack level.",
    ],
  },
];

export default function Resume() {
  const downloadPdf = () => {
    const link = document.createElement("a");
    link.href = "https://drive.google.com/uc?export=download&id=19fJqDJzZnDqbaDnGp78iIPH5ELslykBU";
    link.download = "Pratham Jaiswal - CV.pdf";
  
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
  return (
    <div className="main-container">
      <div className="container-info">
        <div className="header">
          <div className="primary-color-header">Education</div>
        </div>
        <hr />
        <div className="timeline">
          {educationData.map((item, index) => (
            <div className={`timeline-item`} key={index}>
              <div className="dot"></div>
              <div className="content">
                <div className="title">{item.institute}</div>
                <div className="middle">{item.description}</div>
                <div className="duration">{item.duration}</div>
              </div>
            </div>
          ))}
        </div>
        <div className="header">
          <div className="primary-color-header">Experience</div>
        </div>
        <hr />
        <div className="timeline">
          {experienceData.map((item, index) => (
            <div className={`timeline-item`} key={index}>
              <div className="dot"></div>
              <div className="content">
                <div className="title">{item.organisation}</div>
                <div className="middle">{item.role}</div>
                <div className="duration">{item.duration}</div>
                <ul>
                  {item.details.map((detail, detailIndex) => (
                    <li key={detailIndex}>{detail}</li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
        <div className="download-container">
          <button className="download-btn" onClick={downloadPdf}>
            <i className="fa-regular fa-file"></i>
            Download CV
          </button>
        </div>
      </div>
    </div>
  );
}
