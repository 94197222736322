import { useState } from "react";
import "./App.scss";
import About from "./Components/about/about";
import Navbar from "./Components/navbar/navbar";
import TopCard from "./Components/topCard/topCard";
import Portfolio from "./Components/portfolio/portfolio";
import Resume from "./Components/resume/resume";
import Blog from "./Components/blog/blog";
import Contact from "./Components/contact/contact";

export default function App() {
  const [activePage, setActivePage] = useState("About");

  const renderPage = () => {
    switch (activePage) {
      case "Portfolio":
        return <Portfolio />;
      case "Resume":
        return <Resume />;
      case "Blog":
        return <Blog />;
      case "Contact":
        return <Contact />;
      default:
        return <About />;
    }
  };

  return (
    <div className="App">
      <TopCard />
      {renderPage()}
      <Navbar activePage={activePage} setActivePage={setActivePage} />

      <div className="playlist-container">
        <div className="container-info">
          <div className="header">
            <div className="primary-color-header">Vibe</div>
            <div className="secondary-color-header">Along</div>
          </div>
          <hr />
        </div>
        <iframe
          allow="autoplay *; encrypted-media *; fullscreen *; clipboard-write"
          frameborder="0"
          height="450"
          title="playlist"
          className="my-playlist"
          sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
          src="https://embed.music.apple.com/in/playlist/vibe/pl.u-leyl096uMeplv4j?theme=dark"
        ></iframe>
      </div>
    </div>
  );
}
