import { useState } from "react";
import "./topCard.scss";

export default function TopCard() {
  const [isContactVisible, setIsContactVisible] = useState(false);

  const socials = [
    {
      href: "https://www.linkedin.com/in/pratham-jaiswal",
      icon: "fa-linkedin-in",
    },
    { href: "https://github.com/pratham-jaiswal", icon: "fa-github" },
    { href: "https://www.instagram.com/_pratham.dev", icon: "fa-instagram" },
    {
      href: "https://stackoverflow.com/users/14895056/pratham-jaiswals",
      icon: "fa-stack-overflow",
    },
  ];

  const toggleContactArea = () => {
    setIsContactVisible(!isContactVisible);
  };

  return (
    <div className={`top-card-container ${isContactVisible ? "show" : ""}`}>
      <button className="show-contact" onClick={toggleContactArea}>
        <i
          className={`fa-solid fa-chevron-${isContactVisible ? "up" : "down"}`}
        ></i>
      </button>
      <div className="container-info">
        <div className="about">
          <div className="image-container">
            <img src="https://res.cloudinary.com/dhzmockpa/image/upload/v1738419661/profile-pic-2.jpg" alt="Profile" />
          </div>
          <div className="bio">
            <div className="name">
              <div className="first-name">Pratham</div>{" "}
              <div className="last-name">Jaiswal</div>
            </div>
            <div className="statement">Web and App Developer</div>
          </div>
        </div>
        <div className="contact-info">
          <div className="info-row">
            <div className="icon-holder">
              <i className="fa-regular fa-envelope"></i>
            </div>
            <div className="email">
              <a href="mailto:contact@prathamjaiswal.com">
                contact@prathamjaiswal.com
              </a>
            </div>
          </div>
          <div className="socials">
            {socials.map((social, index) => (
              <a
                key={index}
                href={`${social.href}?ref=${window.location.host}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className={`fa-brands ${social.icon}`}></i>
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
